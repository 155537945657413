import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileService from '../../../services/FileService';
import { FileUtils } from '../../../utils/FileUtils';
import { FileInfo } from './FileInfo';
import MultiFileInfo from './MultiFileInfo';
import UploadArea from './UploadArea';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../modal/variants/StandardModal';

type MultiFileModalProps = {
  open: boolean;
  onCancel: () => void;
  onSave: (files: FileInfo[]) => void;
};

const MultiFileModal: FC<MultiFileModalProps> = (props) => {
  const { t } = useTranslation('common');
  const { open, onCancel, onSave } = props;
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [fileErrors, setFileErrors] = useState<Record<string, string | undefined>>({});
  const [fileProgress, setFileProgress] = useState<Record<string, number | undefined>>({});

  const onUpload = useCallback(
    (uploadedFiles: FileInfo[]): void => {
      setFiles((files) => FileUtils.removeDuplicates([...files, ...uploadedFiles]));

      uploadedFiles.forEach((f) => {
        if (f.size >= FileService.MAX_SIZE) {
          setFileErrors((errors) => ({
            ...errors,
            [f.id]: t('file-upload.errors.file-to-large'),
          }));
          return;
        }

        setFileProgress((files) => ({
          ...files,
          [f.id]: 0,
        }));

        const localUploadedFileId = f.id;

        FileService.uploadFile(f.file, (perc) => {
          setFileProgress((files) => ({
            ...files,
            [f.id]: perc,
          }));
        })
          .then((res) => {
            setFileProgress((prev) => {
              const copy = { ...prev };
              delete copy[localUploadedFileId];
              return {
                ...copy,
                [f.id]: undefined,
              };
            });

            f.id = res.data[0].id;
          })
          .catch((err) => {
            setFileErrors((errors) => ({
              ...errors,
              [f.id]: err.meta.message,
            }));
          });
      });
    },
    [t],
  );

  const onFileCancel = useCallback(
    (index: number): void => {
      const fileId = files[index].id;
      setFileErrors((errors) => ({
        ...errors,
        [fileId]: undefined,
      }));
      setFiles((files) => files.filter((_, i) => index != i));
      if (!fileErrors[fileId]) {
        FileService.deleteFile(fileId);
      }
    },
    [fileErrors, files],
  );

  const onCancelClick = useCallback(() => {
    setFiles([]);
    onCancel && onCancel();
  }, [onCancel]);

  const onSaveClicked = useCallback((): void => {
    files && onSave(files);
    setFiles([]);
  }, [files, onSave]);

  const onRename = useCallback((fileId: string, newName: string) => {
    setFiles((prev) => prev.map((f) => (f.id === fileId ? { ...f, name: newName } : f)));
  }, []);

  const rightSideStyle = useMemo(() => (files.length ? 'opacity-100' : 'max-w-0 opacity-0'), [files.length]);

  return (
    <ModalContext.Provider value={{ open: open, onClose: onCancel, modalWidth: files.length > 0 ? 'w-3/5' : 'w-2/5' }}>
      <StandardModal
        title={t('multi-file-upload-modal.heading')}
        cancelButtonTitle={t('file-upload.buttons.cancel')}
        confirmButtonTitle={t('file-upload.buttons.save')}
        onCancelClick={onCancelClick}
        onConfirmClick={files.length > 0 ? onSaveClicked : undefined}
        confirmDisabled={Object.values(fileErrors).some((x) => x !== undefined) || Object.values(fileProgress).some((x) => x !== undefined)}
      >
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex w-full gap-4 overflow-hidden py-6">
            <div className="w-full transition-all duration-300 ease-in">
              <UploadArea onUpload={onUpload} multiple />
            </div>
            <div className={`flex max-h-96 w-full flex-col gap-2 overflow-y-auto pr-4 transition-all duration-300 ease-in ${rightSideStyle}`}>
              {files.map((file, i) => (
                <MultiFileInfo
                  file={file}
                  onRename={(newName) => onRename(file.id, newName)}
                  progress={fileProgress[file.id]}
                  error={fileErrors[file.id]}
                  key={`file-input-${i}`}
                  onCancel={() => onFileCancel(i)}
                />
              ))}
            </div>
          </div>
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default MultiFileModal;
