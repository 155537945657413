import { FC, useCallback, useEffect, useState } from 'react';
import { FileUtils } from '../../../../utils/FileUtils';
import Button, { ButtonSize, ButtonType } from '../../../shared/form-control/Button';
import FileInfoCard from '../../../shared/file-upload/FileInfoCard';
import MultiFileModal from '../../../shared/file-upload/MultiFileModal';
import ActionBaseProps from '../ActionBaseProps';
import { FileInfo } from '../../../shared/file-upload/FileInfo';
import FileService from '../../../../services/FileService';
import { useTranslation } from 'react-i18next';
import SingleFileModal from '../../../shared/file-upload/SingleFileModal';
import { useFormAction } from '../../../../contexts/FormActionContext';
import ActionTitleDescription from '../ActionTitleDescription';

interface MultiFileUploadActionProps extends ActionBaseProps<FileInfo[]> {
  modal?: {
    onClose?: () => void;
    width?: string;
  };
}

const MultiFileUploadAction: FC<MultiFileUploadActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, modal, data, required } = props;
  const { onAnswer, readOnly } = useFormAction(props);
  const [internalFiles, setInternalFiles] = useState<FileInfo[]>(response || []);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editFile, setEditFile] = useState<FileInfo | null>(null);

  useEffect(() => {
    if (response) {
      setInternalFiles(response);
    }
  }, [response]);

  const onModalClose = useCallback(() => {
    setModalOpen(false);
    modal?.onClose && modal.onClose();
  }, [modal]);

  const onFilesUploaded = useCallback(
    (uploadedFiles: FileInfo[]): void => {
      const newFiles = FileUtils.removeDuplicates([...internalFiles, ...uploadedFiles]);
      setInternalFiles(newFiles);
      onAnswer(newFiles);
      onModalClose();
    },
    [internalFiles, onAnswer, onModalClose],
  );

  const onCancel = useCallback(
    (index: number): void => {
      const fileId = internalFiles[index].id;
      FileService.deleteFile(fileId);
      const newFiles = internalFiles.filter((_, i) => index !== i);
      setInternalFiles(newFiles);
      onAnswer(newFiles.length === 0 ? null : newFiles);
    },
    [internalFiles, onAnswer],
  );

  const onFileEdit = useCallback((file: FileInfo) => {
    setEditFile(file);
    setEditModalOpen(true);
  }, []);

  const onEditFileChanged = useCallback(
    (file: FileInfo) => {
      let newFiles = internalFiles.map((f) => (f.id === file.id ? file : f));

      // user replaced a file
      if (!newFiles.find((x) => x.id === file.id)) {
        const oldFileIndex = internalFiles.findIndex((x) => x.id === editFile?.id);
        FileService.deleteFile(internalFiles[oldFileIndex].id);
        newFiles = internalFiles.map((f, i) => (i === oldFileIndex ? file : f));
      }

      setInternalFiles(newFiles);
      onAnswer(newFiles);
      setEditModalOpen(false);
    },
    [editFile?.id, internalFiles, onAnswer],
  );

  return (
    <div data-cy="multi-file-action">
      <ActionTitleDescription required={required} {...data} />
      {internalFiles.length > 0 && (
        <div className="mb-4">
          {internalFiles.map((file, index) => (
            <FileInfoCard file={file} onCancel={() => onCancel(index)} key={`uploaded-file-${index}`} viewOnly={readOnly} onFileEdit={onFileEdit} />
          ))}
        </div>
      )}
      <Button onClick={() => setModalOpen(true)} disabled={readOnly} type={ButtonType.PRIMARY} size={ButtonSize.S} data-cy="upload-document">
        {t('file-upload.buttons.upload-docs')}
      </Button>

      <MultiFileModal open={modalOpen} onSave={onFilesUploaded} onCancel={onModalClose} />
      <SingleFileModal open={editModalOpen} onSave={onEditFileChanged} onCancel={() => setEditModalOpen(false)} file={editFile} />
    </div>
  );
};

export default MultiFileUploadAction;
