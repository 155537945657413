import { FC, useCallback, useState } from 'react';
import { FileUtils } from '../../../utils/FileUtils';
import InlineEditor from '../form-control/InlineEditor';
import RefreshIcon from '../icon/RefreshIcon';
import XIcon from '../icon/XIcon';
import ProgressBar from '../ProgressBar';
import { FileInfo } from './FileInfo';
import AttachmentIcon from '../icon/AttachmentIcon';

type MultiFileInfoProps = {
  file: FileInfo;
  error?: string;
  showRetryButton?: boolean;
  progress?: number;
  onRename: (newName: string) => void;
  onCancel: () => void;
  retry?: () => void;
};

const MultiFileInfo: FC<MultiFileInfoProps> = (props) => {
  const { file, onCancel, error, showRetryButton, retry, progress, onRename } = props;

  const [fileName, setFileName] = useState(file.name);

  const onFileNameChange = useCallback(
    (value: string) => {
      setFileName(value);
      onRename(value);
    },
    [onRename],
  );

  return (
    <div className="relative ml-auto flex w-full items-center" data-cy="multi-file-info-block">
      <AttachmentIcon className="mx-2 h-6 w-6 flex-shrink-0 self-start pt-1" />
      <div className="mx-2 flex-grow">
        <div className="font-medium" data-testid="multi-file-info-name" data-cy="file-name">
          <InlineEditor value={fileName} onChange={onFileNameChange} />
        </div>
        {error !== undefined && (
          <div className="text-color-4" data-cy="file-error">
            {error}
          </div>
        )}
        {!error && progress !== undefined && <ProgressBar progress={progress} />}
        {!error && progress === undefined && (
          <div data-testid="multi-file-info-size" data-cy="file-size">
            {FileUtils.formatSize(file.size)}
          </div>
        )}
      </div>
      {showRetryButton && <RefreshIcon data-cy="retry" className="ml-4 h-6 w-6 rounded-full bg-black p-1 text-white" onClick={retry} />}
      {(!!error || progress === undefined) && (
        <XIcon data-cy="cancel-file" className="ml-4 h-6 w-6 flex-shrink-0 self-start p-1" onClick={onCancel} />
      )}
    </div>
  );
};

export default MultiFileInfo;
